import React, { useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

import Place from "@material-ui/icons/Place";
import Forward from "@material-ui/icons/Forward";
import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import SearchIcon from "@material-ui/icons/Search";
import Delete from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import Danger from "components/Typography/Danger";
import Icon from "@material-ui/core/Icon";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import setAuthToken from "../../utils/setAuthToken";
//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default function Dashboard(props) {
  const classes = useStyles();
  const initialState = {
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: null,
  };
  const [polos, setPolos] = React.useState([]);
  const [txtSearch, setTxtSearch] = React.useState("");
  const [user, setUser] = React.useState(false);
  const [loginModal, setLoginModal] = React.useState(false);
  const [data, setData] = React.useState(initialState);
  const [flag, setFlag] = React.useState("");
  const [errors, setErrors] = React.useState({});

  // CARGA DE LA RONDA
  const fetchData = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // `https://epstool.com/api/polos/all`
      `https://epstool.com/api/polos/allh`
      // `http://localhost:5000/api/polos/allh`
      // `/api/polos/all`
    );
    setPolos(result.data);
    if (!localStorage.jwtToken) {
      //set auth token header auth
      setUser(false);
      // window.location.href = "https://localhost:3010";
    } else {
      setUser(true);
    }
  };
    //login
    const handleInputChange = (event) => {
      setData({
        ...data,
        [event.target.id]: event.target.value,
      });
    };

  const getQueryVariable = async (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
    if (!localStorage.jwtToken) {
      getQueryVariable("token")
        .then((token) => {
          if (token) {
            setAuthToken(token);
            localStorage.setItem("jwtToken", token);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

   //Login
   const verifyLogInSubmit = () => {
    let errorLocal = {};
    !data.email
      ? (errorLocal.email = "Porfavor ingresar un email válido")
      : setFlag(200);
    !data.password
      ? (errorLocal.password = "Porfavor ingresar una contraseña válida")
      : setFlag(200);
    setErrors(errorLocal);
    if (Object.keys(errorLocal).length === 0) {
      handleFormSubmit();
    }
  };

  const handleFormSubmit = () => {
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    fetch("https://epstool.com/api/users/login", {
      // fetch("/api/users/login", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.email.toLowerCase(),
        password: data.password,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setAuthToken(localStorage.jwtToken);
          return res.json();
        }
        throw res;
      })
      .then((resJson) => {
        const token = resJson.token;
        //Set token to LS
        localStorage.setItem("jwtToken", token);
        // setLoginModal(false);
        window.location.reload();
        // return <Redirect to='/admin' />
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          setErrors(errorMessage);
        });
      });
  };

  // // CARGA DE LA RONDA
  // const searchValvula = async (numValvula) => {
  //   if (txtSearch) {
  //     // setAuthToken(localStorage.jwtToken);
  //     const result = await axios.get(
  //       `https://dms.rocks/api/polos/${numValvula}`
  //       // `/api/polos/${numValvula}`
  //     );
  //     setPolos(result.data);
  //   }
  // };

  let tbPolos;
  Object.keys(polos).length >= 1
    ? (tbPolos = polos.map((polo) => {
        return {
          id: polo._id,
          uuid: polo.uuid,
          ubicacion: polo.ubicacion,
          area: polo.areaProductiva,
          actions: (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  props.history.push(`polo/${polo.codigo}/${polo.ubicacion}`);
                }}
                color="success"
                className="edit"
              >
                <Forward />
              </Button>{" "}
            </div>
          ),
        };
      }))
    : (tbPolos = []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              
              <GridContainer>
                
                <GridItem xs={12} sm={12} md={9}>
                <CardIcon color="success">
                  <Place />
                </CardIcon>
                  <h4 className={classes.cardIconTitle}>Lista de Polos</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  {user === true ? (
                    ""
                  ) : (
                    <Button
                      size="sm"
                      className={classes.cardButtonTitle}
                      color="success"
                      onClick={() => {
                        setLoginModal(true);
                      }}
                    >
                      Iniciar Sesión
                    </Button>
                  )}
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {/*             
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Buscar por valvula de cierre:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={5}>
                <CustomInput
                  labelText="# de valvula"
                  id="valvula"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      setTxtSearch(event.target.value);
                    },
                    type: "text",
                    value: txtSearch,
                  }}
                />
              </GridItem>
              <GridItem xs={5} sm={1}>
                <Button
                  simple
                  className={classes.btnSearch}
                  color="success"
                  onClick={() => {
                    searchValvula(txtSearch.toUpperCase());
                  }}
                >
                  <SearchIcon className={classes.icons} /> Buscar
                </Button>
              </GridItem>
              <GridItem xs={6} sm={1}>
                <Button
                  simple
                  className={classes.btnSearch}
                  color="danger"
                  onClick={() => {
                    fetchData();
                  }}
                >
                  <Delete className={classes.icons} /> Resetear
                </Button>
              </GridItem>
            </GridContainer>
     */}
              <br />
              <GridContainer>
                <GridItem xs={12}>
                  <ReactTable
                    columns={[
                      {
                        Header: "Codigo",
                        accessor: "uuid",
                      },
                      {
                        Header: "Ubicacion",
                        accessor: "ubicacion",
                      },
                      {
                        Header: "Area",
                        accessor: "area",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                      },
                    ]}
                    data={tbPolos}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
           {/* MODAL Login */}
           <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={loginModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setLoginModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setLoginModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Iniciar Sesión</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            labelText="Email..."
            id="email"
            type="text"
            value={data.email}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => handleInputChange(event),
              endAdornment: (
                <InputAdornment position="end">
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
          <Danger>
            {errors.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </Danger>
          <CustomInput
            labelText="Password"
            value={data.password}
            id="password"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => handleInputChange(event),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputAdornmentIcon}>
                    lock_outline
                  </Icon>
                </InputAdornment>
              ),
              type: "password",
              autoComplete: "off",
            }}
          />
          <Danger>
            {errors.password && (
              <div className="invalid-feedback">{errors.password}</div>
            )}
          </Danger>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={verifyLogInSubmit} color="success">
            Ingresar
          </Button>
          <Button onClick={() => setLoginModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
