import React, { useState, useEffect } from "react";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import setAuthToken from "../../utils/setAuthToken";
import ReactFilestack from "filestack-react";

import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Place from "@material-ui/icons/Place";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table.js";
import jwt_decode from "jwt-decode";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import CustomInput from "components/CustomInput/CustomInput.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Success from "components/Typography/Success.js";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "components/Snackbar/Snackbar.js";
import Danger from "components/Typography/Danger";
import CardFooter from "components/Card/CardFooter.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function PoloDetails(props) {
  const classes = useStyles();
  const API_KEY = "AWevYlMNkS7KJFbiRivemz";
  const options = {
    maxFiles: 1,
    fromSources: ["local_file_system", "audio"],
  };
  const initialState = {
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: null,
  };
  const [preDeleteId, setPreDeleteId] = React.useState();
  const [preDeleteTipo, setPreDeleteTipo] = React.useState();
  const [data, setData] = React.useState(initialState);
  const [polo, setPolo] = React.useState();
  const [user, setUser] = React.useState(false);
  const [filesModal, setFilesModal] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [nameDescription, setNameDescription] = useState("");
  const [felectricas, setFelectricas] = React.useState([]);
  const [fmecanicas, setFmecanicas] = React.useState([]);
  const [fotros, setFotros] = React.useState([]);
  const [notas, setNotas] = React.useState([]);
  const [bitacora, setBitacora] = React.useState([]);
  const [sessionModal, setSessionModal] = React.useState(false);
  const [notaModal, setNotaModal] = React.useState(false);
  const [loginModal, setLoginModal] = React.useState(false);
  const [serieModal, setSerieModal] = React.useState(false);
  const [eliminarModal, setEliminarModal] = React.useState(false);
  const [eliminarFacilidadModal, setEliminarFacilidadModal] = React.useState(
    false
  );
  const [eliminarNotaModal, setEliminarNotaModal] = React.useState(false);
  const [eliminarFileModal, setEliminarFileModal] = React.useState(false);
  const [textNote, setTextNote] = useState("");
  const [txtSerie, setTxtSerie] = useState("");
  const [txtNotificacion, setTxtNotificacion] = useState("");
  const [tc, setTC] = React.useState(false);
  const [nFElectrica, setNFElectrica] = React.useState([
    {
      tablero: "",
      ckt: "",
      voltaje: 0,
      amperaje: 0,
      conexion: "",
      encargado: "",
    },
  ]);
  const [fElectricaModal, setFElectricaModal] = React.useState(false);
  const [nFMecanica, setNFMecanica] = React.useState([
    {
      id: 0,
      codigo: "",
      nombre: "",
      numeroValvula: "",
      encargado: "",
    },
  ]);
  const [nFMecanicaModal, setNFMecanicaModal] = React.useState(false);
  const [nFOtros, setNFOtros] = React.useState([
    {
      id: 0,
      codigo: "",
      nombre: "",
      encargado: "",
    },
  ]);
  const [nFOtrosModal, setNFOtrosModal] = React.useState(false);
  const codigoPolo = props.match.params.codigo;
  const zona = props.match.params.zona;
  const [errors, setErrors] = React.useState({});
  const [flag, setFlag] = React.useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [urlFile, setUrlFile] = useState();
  const [urlFileError, setUrlFileError] = useState();

  // CARGA DEL Polo
  const fetchData = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // `https://epstool.com/api/polos/${codigoPolo}/${zona}`
      `https://epstool.com/api/polos/${codigoPolo}/${zona}/heredia`
      // `http://localhost:5000/api/polos/${codigoPolo}/${zona}/heredia`
      // `/api/polos/${codigoPolo}/${zona}`
    );
    setPolo(result.data);
    let electricasFilter = [];
    result.data.electricas.forEach((element) => {
      if (element.tablero) {
        electricasFilter.push(element);
      }
    });
    setFelectricas(electricasFilter);
    let mecanicasFilter = [];
    result.data.mecanicas.forEach((element) => {
      if (element.nombre) {
        mecanicasFilter.push(element);
      }
    });
    setFmecanicas(mecanicasFilter);
    let otrosFilter = [];
    result.data.otros.forEach((element) => {
      if (element.nombre) {
        otrosFilter.push(element);
      }
    });
    setFotros(otrosFilter);
    setNotas(result.data.notas);
    setBitacora(result.data.bitacora);
    setFiles(result.data.files);
    // Check for token
    if (!localStorage.jwtToken) {
      //set auth token header auth
      setUser(false);
      // window.location.href = "https://localhost:3010";
    } else {
      setUser(true);
    }
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  //Cambiar Plano
  const handleChangePlano = async (plano, ubicacion) => {
    let ubicacionSinEspacios = ubicacion.replace(/\s+/g, '');
    // console.log(plano)
    await axios
      // .post(`http://localhost:5000/api/polos/editarPlano/CER4`, plano)
      .post(`https://epstool.com/api/polos/editarPlano/${ubicacionSinEspacios}`, plano)
      .then((res) => {
        setTxtNotificacion("Se ha cambiado el plano con éxito");
        setTextNote("");
        showNotification();
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  //Abrir plano
  const handlePlanoOpen = async (ubicacion) => {
    let ubicacionSinEspacios = ubicacion.replace(/\s+/g, '');
    await axios
      .get(
        // `https://epstool.com/api/polos/${codigoPolo}/${zona}`
        // `https://epstool.com/api/polos/${codigoPolo}/${zona}/heredia`
        `https://epstool.com/api/polos/plano/${ubicacionSinEspacios}`
        // `http://localhost:5000/api/polos/plano/CER4`
        // `/api/polos/${codigoPolo}/${zona}`
      )
      .then((res) => {
        window.open(res.data.url, "_blank");
      })
      .catch((error) => {
        console.log(error);
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  // GUARDAR NOTA
  const handleNotaSubmit = async (event) => {
    if (user === false) {
      setSessionModal(true);
    } else {
      event.preventDefault();
      const decoded = jwt_decode(localStorage.jwtToken);
      const notaToSave = {
        nota: textNote,
        userName: decoded.name,
        userCompany: decoded.company,
      };
      await axios
        // .post(`https://dms.rocks/api/polos/nota/${polo._id}`, notaToSave)
        .post(
          `https://epstool.com/api/polos/notaheredia/${polo._id}`,
          // `http://localhost:5000/api/polos/notaheredia/${polo._id}`,
          notaToSave
        )
        .then((res) => {
          setNotas(res.data.polo.notas);
          setTxtNotificacion("Nota guardada con éxito");
          setTextNote("");
          setNotaModal(false);
          showNotification();
        })
        .catch((error) => {
          console.log(error);
          setTxtNotificacion("Se ha producido un error");
          showNotification();
        });
    }
  };

  //Login
  const verifyLogInSubmit = () => {
    let errorLocal = {};
    !data.email
      ? (errorLocal.email = "Porfavor ingresar un email válido")
      : setFlag(200);
    !data.password
      ? (errorLocal.password = "Porfavor ingresar una contraseña válida")
      : setFlag(200);
    setErrors(errorLocal);
    if (Object.keys(errorLocal).length === 0) {
      handleFormSubmit();
    }
  };

  const handleFormSubmit = () => {
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    fetch("https://epstool.com/api/users/login", {
      // fetch("/api/users/login", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.email.toLowerCase(),
        password: data.password,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setAuthToken(localStorage.jwtToken);
          return res.json();
        }
        throw res;
      })
      .then((resJson) => {
        const token = resJson.token;
        //Set token to LS
        localStorage.setItem("jwtToken", token);
        // setLoginModal(false);
        window.location.reload();
        // return <Redirect to='/admin' />
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          setErrors(errorMessage);
        });
      });
  };

  // GUARDAR Serie
  const handleSerieSubmit = async (event) => {
    if (user === false) {
      setSessionModal(true);
    } else {
      event.preventDefault();
      const serieToSave = {
        serie: txtSerie,
      };
      await axios
        // .post(`https://dms.rocks/api/polos/serie/${polo._id}`, serieToSave)
        .post(`https://epstool.com/api/polos/serie/${polo._id}`, serieToSave)
        // .post(`http://localhost:5000/api/polos/serie/${polo._id}`, serieToSave)
        .then((res) => {
          // console.log(res.data);

          setPolo(res.data);
          // setNotas(res.data.polo.notas);
          setTxtNotificacion("Serie guardada con éxito");
          setTxtSerie("");
          setSerieModal(false);
          showNotification();
        })
        .catch((error) => {
          console.log(error);
          setTxtNotificacion("Se ha producido un error");
          showNotification();
        });
    }
  };

  // Eliminar polos
  const handleDelete = async () => {
    const decoded = jwt_decode(localStorage.jwtToken);

    await axios
      // .delete(`https://dms.rocks/api/polos/eliminarPolo/${polo._id}`)
      .post(
        `https://epstool.com/api/polos/eliminarPoloHeredia/${polo._id}`,
        // `http://localhost:5000/api/polos/eliminarPoloHeredia/${polo._id}`,
        decoded
      )
      .then((res) => {
        props.history.push(`../admin`);
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  //login
  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.id]: event.target.value,
    });
  };

  // GUARDAR Facilidad Electrica
  const handleElectricaSubmit = async (event) => {
    if (user === false) {
      setSessionModal(true);
    } else {
      event.preventDefault();
      let decoded = jwt_decode(localStorage.jwtToken);
      const nuevoelectrico = {
        tablero: nFElectrica[0].tablero,
        ckt: nFElectrica[0].ckt,
        voltaje: nFElectrica[0].voltaje,
        amperaje: nFElectrica[0].amperaje,
        conexion: nFElectrica[0].conexion,
        encargado: nFElectrica[0].encargado,
        userName: decoded.name,
        userCompany: decoded.company,
        userId: decoded.id,
      };

      await axios
        .post(
          `https://epstool.com/api/polos/facilidadHeredia/${polo._id}/1`,
          // `http://localhost:5000/api/polos/facilidadHeredia/${polo._id}/1`,
          nuevoelectrico
        )
        // .post(`/api/polos/facilidad/${polo._id}/1`, nuevoelectrico)
        .then((res) => {
          setFelectricas(res.data.polo.electricas);
          setTxtNotificacion("Facilidad eléctrica guardada con éxito");
          setNFElectrica([
            {
              tablero: "",
              ckt: "",
              voltaje: 0,
              amperaje: 0,
              conexion: "",
              encargado: "",
            },
          ]);
          setFElectricaModal(false);
          showNotification();
        })
        .catch((error) => {
          setTxtNotificacion("Se ha producido un error");
          showNotification();
        });
    }
  };

  // GUARDAR Facilidad Mecanica
  const handleMecanicaSubmit = async (event) => {
    if (user === false) {
      setSessionModal(true);
    } else {
      event.preventDefault();
      const decoded = jwt_decode(localStorage.jwtToken);
      const nuevomecanico = {
        codigo: nFMecanica[0].codigo,
        nombre: nFMecanica[0].nombre,
        numeroValvula: nFMecanica[0].numeroValvula,
        encargado: nFElectrica[0].encargado,
        userName: decoded.name,
        userCompany: decoded.company,
        userId: decoded.id,
      };

      await axios
        .post(
          `https://epstool.com/api/polos/facilidadHeredia/${polo._id}/2`,
          // `http://localhost:5000/api/polos/facilidadHeredia/${polo._id}/2`,
          nuevomecanico
        )
        // .post(`/api/polos/facilidad/${polo._id}/2`, nuevomecanico)
        .then((res) => {
          setFmecanicas(res.data.polo.mecanicas);
          setTxtNotificacion("Facilidad mecánica guardada con éxito");
          setNFMecanica([
            {
              id: 0,
              codigo: "",
              nombre: "",
              numeroValvula: "",
              encargado: "",
            },
          ]);
          setNFMecanicaModal(false);
          showNotification();
        })
        .catch((error) => {
          setTxtNotificacion("Se ha producido un error");
          showNotification();
        });
    }
  };

  // GUARDAR Facilidad Otros
  const handleOtrosSubmit = async (event) => {
    if (user === false) {
      setSessionModal(true);
    } else {
      event.preventDefault();
      const decoded = jwt_decode(localStorage.jwtToken);
      const nuevootros = {
        codigo: nFOtros[0].codigo,
        nombre: nFOtros[0].nombre,
        encargado: nFOtros[0].encargado,
        userName: decoded.name,
        userCompany: decoded.company,
        userId: decoded.id,
      };

      await axios
        .post(
          `https://epstool.com/api/polos/facilidadHeredia/${polo._id}/3`,
          // `http://localhost:5000/api/polos/facilidadHeredia/${polo._id}/3`,
          nuevootros
        )
        // .post(`/api/polos/facilidad/${polo._id}/3`, nuevootros)
        .then((res) => {
          setFotros(res.data.polo.otros);
          setTxtNotificacion("Otra Facilidad guardada con éxito");
          setNFOtros([
            {
              id: 0,
              codigo: "",
              nombre: "",
              encargado: "",
            },
          ]);
          setNFOtrosModal(false);
          showNotification();
        })
        .catch((error) => {
          console.log(error);
          setTxtNotificacion("Se ha producido un error");
          showNotification();
        });
    }
  };

  // Eliminar facilidad
  const deleteFacilidad = async (id, tipo) => {
    await axios
      .delete(
        `https://epstool.com/api/polos/heredia/${codigoPolo}/${zona}/${id}/${tipo}`
        // `http://localhost:5000/api/polos/heredia/${codigoPolo}/${zona}/${id}/${tipo}`
      )
      // .delete(`/api/polos/${codigoPolo}/${zona}/${id}/${tipo}`)
      .then((res) => {
        switch (tipo) {
          case "1":
            setFelectricas(res.data.polo.electricas);
            break;
          case "2":
            setFmecanicas(res.data.polo.mecanicas);
            break;
          case "3":
            setFotros(res.data.polo.otros);
            break;
          default:
            break;
        }
        setEliminarFacilidadModal(false);
        setPreDeleteId("");
        setTxtNotificacion("Facilidad eliminada con éxito");
        showNotification();
      })
      .catch((error) => {
        console.log(error);
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  // Eliminar Nota
  const deleteNota = async (idNota, idPolo) => {
    await axios
      .delete(
        // `https://epstool.com/api/polos/heredia/${codigoPolo}/${zona}/${id}/${tipo}`
        // `http://localhost:5000/api/polos/nota/${idPolo}/${idNota}`
        `https://epstool.com/api/polos/nota/${idPolo}/${idNota}`
      )
      // .delete(`/api/polos/${codigoPolo}/${zona}/${id}/${tipo}`)
      .then((res) => {
        setNotas(res.data.notas);
        setEliminarNotaModal(false);
        setPreDeleteId("");
        setTxtNotificacion("Nota eliminada con éxito");
        showNotification();
      })
      .catch((error) => {
        console.log(error);
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  // Eliminar Archivo
  const deleteFile = async (idArchivo, idPolo) => {
    await axios
      .delete(
        // `https://epstool.com/api/polos/heredia/${codigoPolo}/${zona}/${id}/${tipo}`
        // `http://localhost:5000/api/polos/file/${idPolo}/${idArchivo}`
        `https://epstool.com/api/polos/file/${idPolo}/${idArchivo}`
      )
      // .delete(`/api/polos/${codigoPolo}/${zona}/${id}/${tipo}`)
      .then((res) => {
        setFiles(res.data.files);
        setEliminarFileModal(false);
        setPreDeleteId("");
        setTxtNotificacion("Archivo eliminado con éxito");
        showNotification();
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  //Manejo de archivos
  const handleFileSubmit = async (event) => {
    event.preventDefault();
    setUrlFileError();
    setErrorDescription();
    let flag = 0;
    !nameDescription
      ? setErrorDescription("Debe agregar un nombre al archivo")
      : (flag = flag + 1);
    !urlFile
      ? setUrlFileError("Debe adjuntar un documento o imagen")
      : (flag = flag + 1);

    if (flag === 2) {
      const decoded = jwt_decode(localStorage.jwtToken);
      const fileToSave = {
        url: urlFile.url,
        fileName: urlFile.filename,
        fileDescription: nameDescription,
        type: urlFile.mimetype,
        userName: decoded.name,
        userCompany: decoded.company,
      };
      await axios
        .post(`https://epstool.com/api/polos/fileh/${polo._id}`, fileToSave)
        // .post(`http://localhost:5000/api/polos/fileh/${polo._id}`, fileToSave)
        // .post(`/api/maquinas/file/${equipo._id}`, fileToSave)
        .then((res) => {
          setFiles(res.data.polo.files);
          setTxtNotificacion("Archivo guardado con éxito");
          setNameDescription("");
          setUrlFile("");
          setFilesModal(false);
          showNotification();
        })
        .catch((error) => {
          setTxtNotificacion("Se ha producido un error");
          showNotification();
        });
    }
  };

  return (
    <div>
      {/* Informacion tecnica */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <CardIcon color="success">
                    <Place />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    Polo {polo ? polo.uuid : ""}
                  </h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Button
                    size="sm"
                    className={classes.cardButtonTitle}
                    color="success"
                  >
                    <a
                      className={classes.acolor}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        "https://cdn.filestackcontent.com/GTVfIWJfStmNi1OjLsnL"
                      }
                    >
                      Guía de uso
                    </a>
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <p>Ubicación {polo ? polo.ubicacion : ""}</p>
                </GridItem>
                <GridItem xs={12}>
                  <p>Área productiva {polo ? polo.areaProductiva : ""}</p>
                </GridItem>
                <GridItem xs={12}>
                  <p>Tipo {polo ? polo.tipo : ""}</p>
                </GridItem>
                <GridItem xs={12}>
                  <p>
                    {polo
                      ? polo.codigoAntiguo
                        ? `Código anterior ${polo.codigoAntiguo}`
                        : ""
                      : ""}
                  </p>
                </GridItem>
                {/* <GridItem xs={12}>
                  <p>Número de activo {polo ? polo.serie : ""}</p>
                </GridItem> */}
              </GridContainer>
            </CardBody>
            <CardFooter>
              <GridContainer>
                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={12} md={4}>
                      <Button
                        fullWidth
                        size="sm"
                        color="success"
                        onClick={() => {
                          props.history.push(`../../dashboard`);
                        }}
                      >
                        Ir a búsqueda de polos
                      </Button>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <Button
                        fullWidth
                        size="sm"
                        color="success"
                        onClick={() => handlePlanoOpen(polo.ubicacion)}
                      >
                        Descargar plano {polo ? polo.ubicacion : ""}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <Button fullWidth size="sm" color="success">
                        <a
                          className={classes.acolor}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            "https://www.cognitoforms.com/FusionTechDigitalSolutions/SolicitudDeRegistroDePolos"
                          }
                        >
                          Ir plantilla para nuevos registros
                        </a>
                      </Button>
                    </GridItem>
                  </GridContainer>

                  {user ? (
                    <GridContainer>
                      <GridItem xs={12} md={4}>
                        <Button
                          fullWidth
                          size="sm"
                          color="success"
                          onClick={() => {
                            props.history.push(
                              `../../editar/${polo.codigo}/${polo.ubicacion}`
                            );
                          }}
                        >
                          Editar
                        </Button>
                      </GridItem>
                      {/* <GridItem xs={12} md={4}>
                        <Button
                          fullWidth
                          size="sm"
                          color="success"
                          onClick={() => {
                            setSerieModal(true);
                          }}
                        >
                          Agregar Número de activo
                        </Button>
                      </GridItem> */}
                      <GridItem xs={12} md={4}>
                        <ReactFilestack
                          apikey={API_KEY}
                          onSuccess={(result) =>
                            handleChangePlano(result.filesUploaded[0], polo.ubicacion)
                          }
                          onError={(error) =>
                            // setUrlFileError(
                            //   "Se ha producido un error, porfavor intentarlo nuevamente"
                            // )
                            console.log(error)
                          }
                          actionOptions={options}
                          customRender={({ onPick }) => (
                            <div>
                              <Button
                                fullWidth
                                size="sm"
                                color="success"
                                onClick={onPick}
                              >
                                Cambiar plano
                              </Button>
                            </div>
                          )}
                        />
                        {/* <Button
                          fullWidth
                          size="sm"
                          color="success"
                          // onClick={() => {
                          //   setSerieModal(true);
                          // }}
                        >
                          Cambiar plano
                        </Button> */}
                      </GridItem>
                      <GridItem xs={12} md={4}>
                        <Button
                          fullWidth
                          size="sm"
                          color="danger"
                          onClick={() => {
                            setEliminarModal(true);
                          }}
                        >
                          Eliminar
                        </Button>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <GridContainer>
                      <GridItem xs={12} md={12}>
                        <Button
                          fullWidth
                          size="sm"
                          color="info"
                          onClick={() => {
                            setLoginModal(true);
                          }}
                        >
                          Iniciar Sesión
                        </Button>
                      </GridItem>
                    </GridContainer>
                  )}
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      {/* Facilidades Electricas */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <h4 className={classes.cardTitle}>Facilidades Eléctricas</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  {user === true ? (
                    <Button
                      size="sm"
                      className={classes.cardButtonTitle}
                      color="success"
                      onClick={() => {
                        setFElectricaModal(true);
                      }}
                    >
                      Nueva Facilidad
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      className={classes.cardButtonTitle}
                      color="success"
                      onClick={() => {
                        setSessionModal(true);
                      }}
                    >
                      Nueva Facilidad
                    </Button>
                  )}
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {felectricas === 0 ? (
                "No contiene facilidades eléctricas"
              ) : (
                <Table
                  tableHead={[
                    "Tablero",
                    "CKT",
                    "Voltaje",
                    "Amperaje",
                    "Conexión",
                    user === true ? "Actions" : null,
                  ]}
                  tableData={felectricas.map((electrica) => [
                    electrica.tablero,
                    electrica.ckt,
                    `${electrica.voltaje} V`,
                    `${electrica.amperaje} A`,
                    electrica.conexion,
                    user === true ? (
                      <Button
                        justIcon
                        color="danger"
                        size="sm"
                        onClick={() => {
                          setPreDeleteId(electrica._id);
                          setPreDeleteTipo("1");
                          setEliminarFacilidadModal(true);
                        }}
                      >
                        <Delete />
                      </Button>
                    ) : null,
                  ])}
                  customCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customClassesForCells={[0, 3]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customHeadClassesForCells={[0, 3]}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* Facilidades Mecanidas */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <h4 className={classes.cardTitle}>Facilidades Mecánicas</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  {user === true ? (
                    <Button
                      size="sm"
                      className={classes.cardButtonTitle}
                      color="success"
                      onClick={() => {
                        setNFMecanicaModal(true);
                      }}
                    >
                      Nueva Facilidad
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      className={classes.cardButtonTitle}
                      color="success"
                      onClick={() => {
                        setSessionModal(true);
                      }}
                    >
                      Nueva Facilidad
                    </Button>
                  )}
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {fmecanicas && fmecanicas.length === 0 ? (
                "No contiene facilidades mecánicas"
              ) : (
                <Table
                  tableHead={[
                    "Nombre",
                    "Codigo",
                    "# Valvula",
                    "Fecha",
                    user === true ? "Actions" : null,
                  ]}
                  tableData={fmecanicas.map((mecanica) => [
                    mecanica.nombre,
                    mecanica.codigo,
                    mecanica.numeroValvula,
                    new Date(mecanica.fechaRegistro * 1000).toLocaleString(),
                    user === true ? (
                      <Button
                        justIcon
                        color="danger"
                        size="sm"
                        onClick={() => {
                          setPreDeleteId(mecanica._id);
                          setPreDeleteTipo("2");
                          setEliminarFacilidadModal(true);
                        }}
                      >
                        <Delete />
                      </Button>
                    ) : null,
                  ])}
                  customCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customClassesForCells={[0, 3]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customHeadClassesForCells={[0, 3]}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* Otras facilidades */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <h4 className={classes.cardTitle}>Otras Facilidades</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  {user === true ? (
                    <Button
                      size="sm"
                      className={classes.cardButtonTitle}
                      color="success"
                      onClick={() => {
                        setNFOtrosModal(true);
                      }}
                    >
                      Nueva Facilidad
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      className={classes.cardButtonTitle}
                      color="success"
                      onClick={() => {
                        setSessionModal(true);
                      }}
                    >
                      Nueva Facilidad
                    </Button>
                  )}
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {fotros.length === 0 ? (
                "No contiene otras facilidades"
              ) : (
                <Table
                  tableHead={[
                    "Nombre",
                    "Codigo",
                    "Fecha",
                    user === true ? "Actions" : null,
                  ]}
                  tableData={fotros.map((otro) => [
                    otro.nombre,
                    otro.codigo,

                    new Date(otro.fechaRegistro * 1000).toLocaleString(),
                    user === true ? (
                      <Button
                        justIcon
                        color="danger"
                        size="sm"
                        onClick={() => {
                          setPreDeleteId(otro._id);
                          setPreDeleteTipo("3");
                          setEliminarFacilidadModal(true);
                        }}
                      >
                        <Delete />
                      </Button>
                    ) : null,
                  ])}
                  customCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customClassesForCells={[0, 3]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right,
                  ]}
                  customHeadClassesForCells={[0, 3]}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* NOTAS */}
      {user === true ? (
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={9}>
                    <h4 className={classes.cardTitle}>Notas</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {user === true ? (
                      <Button
                        size="sm"
                        className={classes.cardButtonTitle}
                        color="success"
                        onClick={() => {
                          setNotaModal(true);
                        }}
                      >
                        Crear nota
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        className={classes.cardButtonTitle}
                        color="success"
                        onClick={() => {
                          setSessionModal(true);
                        }}
                      >
                        Crear nota
                      </Button>
                    )}
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {notas.length === 0
                  ? "No se registran notas"
                  : notas.map((nota) => (
                      <GridContainer key={nota._id}>
                        <GridItem xs={10}>
                          <strong>
                            Nota del dia{" "}
                            {new Date(nota.fecha * 1000).toLocaleString()}:
                          </strong>
                        </GridItem>
                        <GridItem xs={2}>
                          <Button
                            justIcon
                            color="danger"
                            size="sm"
                            onClick={() => {
                              setPreDeleteId(nota._id);
                              setEliminarNotaModal(true);
                            }}
                          >
                            <Delete />
                          </Button>
                        </GridItem>
                        <GridItem xs={12}>
                          <p>{nota.comentario}.</p>
                        </GridItem>
                        <GridItem xs={12}>
                          <p>
                            Realizado por {nota.userName} - {nota.userCompany}
                          </p>
                        </GridItem>
                      </GridContainer>
                      // <div key={nota._id}>
                      //   <strong>
                      //     Nota del dia{" "}
                      //     {new Date(nota.fecha * 1000).toLocaleString()}:
                      //   </strong>
                      //   <p>{nota.comentario}.</p>
                      //   <p>
                      //     Realizado por {nota.userName} - {nota.userCompany}
                      //   </p>
                      // </div>
                    ))}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}

      {/* Archivos */}
      {user === true ? (
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={9}>
                    <h4 className={classes.cardTitle}>Archivos</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {user === true ? (
                      <Button
                        size="sm"
                        className={classes.cardButtonTitle}
                        color="success"
                        onClick={() => {
                          setFilesModal(true);
                        }}
                      >
                        Agregar archivo
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        className={classes.cardButtonTitle}
                        color="success"
                        onClick={() => {
                          setSessionModal(true);
                        }}
                      >
                        Agregar archivo
                      </Button>
                    )}
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {files.length === 0
                  ? "No se registran archivos"
                  : files.map((file) => (
                      <GridContainer key={file._id}>
                        <GridItem xs={10}>
                          <strong>
                            Archivo almacendo el dia{" "}
                            {new Date(file.fecha * 1000).toLocaleString()}:
                          </strong>
                        </GridItem>
                        <GridItem xs={2}>
                          <Button
                            justIcon
                            color="danger"
                            size="sm"
                            onClick={() => {
                              setPreDeleteId(file._id);
                              setEliminarFileModal(true);
                            }}
                          >
                            <Delete />
                          </Button>
                        </GridItem>
                        <GridItem xs={12}>
                          <strong>
                            <a href={file.url} target="_blank">
                              Ver archivo {file.fileDescripcion}
                            </a>
                          </strong>
                        </GridItem>
                        <GridItem xs={12}>
                          <p>
                            Realizado por {file.userName} - {file.userCompany}
                          </p>
                        </GridItem>
                      </GridContainer>
                    ))}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}

      {/* Bitacora */}
      {user === true ? (
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={9}>
                    <h4 className={classes.cardTitle}>Bitácora</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {bitacora.length === 0
                  ? "No se registran acciones"
                  : bitacora.map((bitacora) => (
                      <div key={bitacora._id}>
                        <strong>
                          Fecha:{" "}
                          {new Date(
                            bitacora.creationDate * 1000
                          ).toLocaleString()}
                          :
                        </strong>
                        <p>{bitacora.text}.</p>
                        <p>
                          Realizado por {bitacora.encargado} -{" "}
                          {bitacora.empresa}
                        </p>
                      </div>
                    ))}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}

      {/* MODAL NOTA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={notaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setNotaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setNotaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Crear Nota</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir comentario"
            id="textNote"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTextNote(event.target.value);
              },
              type: "text",
              value: textNote,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleNotaSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setNotaModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL Facilidades electricas */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={fElectricaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setFElectricaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setFElectricaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Nueva Facilidad Eléctrica</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            labelText="Codigo de Panel"
            id="codpanel"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                nFElectrica[0].tablero = event.target.value;
                setNFElectrica([...nFElectrica]);
              },
              type: "text",
              value: nFElectrica[0].tablero,
            }}
          />
          <CustomInput
            labelText="CKT"
            id="ckt"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                nFElectrica[0].ckt = event.target.value;
                setNFElectrica([...nFElectrica]);
              },
              type: "text",
              value: nFElectrica[0].ckt,
            }}
          />
          <CustomInput
            labelText="Voltaje"
            id="voltaje"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                nFElectrica[0].voltaje = event.target.value;
                setNFElectrica([...nFElectrica]);
              },
              type: "number",
              value: nFElectrica[0].voltaje,
            }}
          />
          <CustomInput
            labelText="Amperaje"
            id="amperaje"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                nFElectrica[0].amperaje = event.target.value;
                setNFElectrica([...nFElectrica]);
              },
              type: "number",
              value: nFElectrica[0].amperaje,
            }}
          />
          <CustomInput
            labelText="Encargado"
            id="encargado"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                nFElectrica[0].encargado = event.target.value;
                setNFElectrica([...nFElectrica]);
              },
              type: "text",
              value: nFElectrica[0].encargado,
            }}
          />
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Conexión*
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={nFElectrica[0].conexion}
              onChange={(event) => {
                nFElectrica[0].conexion = event.target.value;
                setNFElectrica([...nFElectrica]);
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Conexión
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="TWISLOCK"
              >
                Twislock
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="DUPLEX"
              >
                Duplex
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleElectricaSubmit} color="success">
            Guardar
          </Button>
          <Button
            onClick={() => setFElectricaModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL Facilidades mecanicas */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={nFMecanicaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setNFMecanicaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setNFMecanicaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Nueva Facilidad Mecánica</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            labelText="Codigo"
            id="codigo"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                nFMecanica[0].codigo = event.target.value;
                setNFMecanica([...nFMecanica]);
              },
              type: "text",
              value: nFMecanica[0].codigo,
            }}
          />
          <CustomInput
            labelText="Nombre"
            id="nombre"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                nFMecanica[0].nombre = event.target.value;
                setNFMecanica([...nFMecanica]);
              },
              type: "text",
              value: nFMecanica[0].nombre,
            }}
          />
          <CustomInput
            labelText="# de valvula"
            id="numValvula"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                nFMecanica[0].numeroValvula = event.target.value;
                setNFMecanica([...nFMecanica]);
              },
              type: "text",
              value: nFMecanica[0].numeroValvula,
            }}
          />
          <CustomInput
            labelText="Encargado"
            id="encargado"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                nFMecanica[0].encargado = event.target.value;
                setNFMecanica([...nFMecanica]);
              },
              type: "text",
              value: nFMecanica[0].encargado,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleMecanicaSubmit} color="success">
            Guardar
          </Button>
          <Button
            onClick={() => setNFMecanicaModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL otras facilidades */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={nFOtrosModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setNFOtrosModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setNFOtrosModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Nueva Facilidad</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            labelText="Codigo"
            id="codigo"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                nFOtros[0].codigo = event.target.value;
                setNFOtros([...nFOtros]);
              },
              type: "text",
              value: nFOtros[0].codigo,
            }}
          />
          <CustomInput
            labelText="Nombre"
            id="nombre"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                nFOtros[0].nombre = event.target.value;
                setNFOtros([...nFOtros]);
              },
              type: "text",
              value: nFOtros[0].nombre,
            }}
          />
          <CustomInput
            labelText="Encargado"
            id="encargado"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                nFOtros[0].encargado = event.target.value;
                setNFOtros([...nFOtros]);
              },
              type: "text",
              value: nFOtros[0].encargado,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleOtrosSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setNotaModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL iniciar sesion */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={sessionModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSessionModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setSessionModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>Se necesita iniciar sesión para realizar esta acción</h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              setSessionModal(false);
              setLoginModal(true);
            }}
            color="success"
          >
            Iniciar Sesión
          </Button>
          <Button onClick={() => setSessionModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Eliminar */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={eliminarModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEliminarModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEliminarModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>
            ¿Está seguro de eliminar este polo? Esta acción no puede deshacerse.
          </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleDelete} color="success">
            Eliminar
          </Button>
          <Button onClick={() => setEliminarModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Eliminar facilidad */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={eliminarFacilidadModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEliminarFacilidadModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEliminarFacilidadModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>
            ¿Está seguro de eliminar esta facilidad? Esta acción no puede
            deshacerse.
          </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => deleteFacilidad(preDeleteId, preDeleteTipo)}
            color="success"
          >
            Eliminar
          </Button>
          <Button
            onClick={() => setEliminarFacilidadModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Eliminar nota */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={eliminarNotaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEliminarNotaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEliminarNotaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>
            ¿Está seguro de eliminar esta nota? Esta acción no puede deshacerse.
          </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => deleteNota(preDeleteId, polo._id)}
            color="success"
          >
            Eliminar
          </Button>
          <Button
            onClick={() => setEliminarNotaModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Eliminar archivo */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={eliminarFileModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEliminarFileModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEliminarFileModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>
            ¿Está seguro de eliminar este archivo? Esta acción no puede
            deshacerse.
          </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => deleteFile(preDeleteId, polo._id)}
            color="success"
          >
            Eliminar
          </Button>
          <Button
            onClick={() => setEliminarFileModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL SERIE */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={serieModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSerieModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setSerieModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Número de Serie</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir número de activo"
            id="txtSerie"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTxtSerie(event.target.value);
              },
              type: "text",
              value: txtSerie,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleSerieSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setSerieModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Login */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={loginModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setLoginModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setLoginModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Iniciar Sesión</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            labelText="Email..."
            id="email"
            type="text"
            value={data.email}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => handleInputChange(event),
              endAdornment: (
                <InputAdornment position="end">
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
          <Danger>
            {errors.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </Danger>
          <CustomInput
            labelText="Password"
            value={data.password}
            id="password"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => handleInputChange(event),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputAdornmentIcon}>
                    lock_outline
                  </Icon>
                </InputAdornment>
              ),
              type: "password",
              autoComplete: "off",
            }}
          />
          <Danger>
            {errors.password && (
              <div className="invalid-feedback">{errors.password}</div>
            )}
          </Danger>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={verifyLogInSubmit} color="success">
            Ingresar
          </Button>
          <Button onClick={() => setLoginModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL ARCHIVO */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={filesModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setFilesModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setFilesModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Agregar archivo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir nombre de archivo"
            id="nameDescription"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setNameDescription(event.target.value);
              },
              type: "text",
              value: nameDescription,
            }}
          />
          {errorDescription ? (
            <Danger>
              <p> {errorDescription} </p>
            </Danger>
          ) : null}
          <ReactFilestack
            apikey={API_KEY}
            onSuccess={(result) => setUrlFile(result.filesUploaded[0])}
            onError={(error) =>
              setUrlFileError(
                "Se ha producido un error, porfavor intentarlo nuevamente"
              )
            }
            actionOptions={options}
            customRender={({ onPick }) => (
              <div>
                <Button fullWidth color="info" onClick={onPick}>
                  Cargar archivo
                </Button>
                {urlFile ? (
                  <Success>
                    <p> Archivo cargado </p>
                  </Success>
                ) : null}
                {urlFileError ? (
                  <Danger>
                    <p>{urlFileError}</p>
                  </Danger>
                ) : null}
              </div>
            )}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleFileSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setFilesModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}
