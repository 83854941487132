import React from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import AddCircle from "@material-ui/icons/AddCircle";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Danger from "components/Typography/Danger";
import Divider from "@material-ui/core/Divider";
import Snackbar from "components/Snackbar/Snackbar.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";

// import Divider from "@material-ui/core/Divider";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function CrearPolo(props) {
  const [user, setUser] = React.useState();

  const fetchData = async () => {
    try {
      let userPre = jwt_decode(localStorage.jwtToken);
      setUser(userPre);
    } catch (error) {
      props.history.push(`../dashboard`);
    }
  };

  // CARGA INICIAL
  React.useEffect(() => {
    fetchData();
  }, []);

  const classes = useStyles();

  const [electrica, setElectrica] = React.useState([
    {
      id: 0,
      tablero: "",
      ckt: "",
      voltaje: 0,
      amperaje: 0,
      conexion: "",
    },
  ]);
  const [mecanica, setMecanica] = React.useState([
    {
      id: 0,
      codigo: "",
      nombre: "",
      numeroValvula: "",
    },
  ]);
  const [otro, setOtro] = React.useState([
    {
      id: 0,
      codigo: "",
      nombre: "",
    },
  ]);
  const [codigo, setCodigo] = React.useState("");
  const [codigoAnt, setCodigoAnt] = React.useState("");
  const [serie, setSerie] = React.useState("");
  const [ubicacion, setUbicacion] = React.useState("");
  const [area, setArea] = React.useState("");
  const [tipo, setTipo] = React.useState("");
  const [encargado, setEncargado] = React.useState("");
  const [errTipoName, setErrTipoName] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [flag, setFlag] = React.useState("");

  const [txtNotificacion, setTxtNotificacion] = React.useState(
    "Polo almacenado correctamente"
  );
  const [tc, setTC] = React.useState(false);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  // Verificar
  const verifySubmit = () => {
    let errorLocal = {};

    codigo.length < 1
      ? (errorLocal.codigo = "Porfavor ingresar un código válido")
      : setFlag(200);

    ubicacion.length < 1
      ? (errorLocal.ubicacion = "Porfavor ingresar ubicación válida")
      : setFlag(200);

    area.length < 1
      ? (errorLocal.area = "Porfavor ingresar área válida")
      : setFlag(200);

    tipo.length < 1
      ? (errorLocal.tipo = "Porfavor ingresar un tipo válido")
      : setFlag(200);

    setErrors(errorLocal);
    if (Object.keys(errorLocal).length === 0) {
      console.log(errors);
      handleTipoSubmit();
    }
  };

  const handleTipoSubmit = async () => {
    const poloToSave = {
      codigo: codigo,
      serie: serie,
      ubicacion: ubicacion,
      areaProductiva: area,
      tipo: tipo,
      encargado: encargado,
      electricas: electrica,
      mecanicas: mecanica,
      otros: otro,
      user: user,
      codigoAntiguo: codigoAnt,
    };
    await axios
      // .post("https://epstool.com/api/polos/nuevopolo", poloToSave)
      .post("https://epstool.com/api/polos/nuevopoloh", poloToSave)
      // .post("http://localhost:5000/api/polos/nuevopoloh", poloToSave)
      // .post("/api/polos/nuevopolo", poloToSave)
      .then((res) => {
        showNotification();
        setCodigo("");
        setSerie("");
        setUbicacion("");
        setArea("");
        setTipo("");
        setEncargado("");
        setElectrica([
          {
            id: 0,
            tablero: "",
            ckt: "",
            voltaje: 0,
            amperaje: 0,
            conexion: "",
          },
        ]);
        setMecanica([
          {
            id: 0,
            codigo: "",
            nombre: "",
            numeroValvula: "",
          },
        ]);
        setOtro([
          {
            id: 0,
            codigo: "",
            nombre: "",
          },
        ]);
        setErrors([{ id: 0, name: "", tipo: "", obligatorio: "" }]);
        // showNotification();
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
    // }
  };

  const handleElectricaInput = async (event, id, inpt) => {
    event.preventDefault();
    let txt = event.target.value;
    switch (inpt) {
      case 1:
        electrica[id].tablero = txt;
        break;
      case 2:
        electrica[id].ckt = txt;
        break;
      case 3:
        // parseFloat(txt);
        electrica[id].voltaje = txt;
        break;
      case 4:
        // parseFloat(txt);
        electrica[id].amperaje = txt;
        break;
      case 5:
        electrica[id].conexion = txt;
        break;
      default:
        break;
    }
    setElectrica([...electrica]);
  };

  const handleMecanicaInput = async (event, id, inpt) => {
    event.preventDefault();
    let txt = event.target.value;
    switch (inpt) {
      case 1:
        mecanica[id].codigo = txt;
        break;
      case 2:
        mecanica[id].nombre = txt;
        break;
      case 3:
        // parseFloat(txt);
        mecanica[id].numeroValvula = txt;
        break;
      default:
        break;
    }
    setElectrica([...electrica]);
  };

  const handleOtroInput = async (event, id, inpt) => {
    event.preventDefault();
    let txt = event.target.value;
    switch (inpt) {
      case 1:
        otro[id].codigo = txt;
        break;
      case 2:
        otro[id].nombre = txt;
        break;
      default:
        break;
    }
    setElectrica([...electrica]);
  };

  const handleAddElectrica = async (event) => {
    event.preventDefault();
    setElectrica([
      ...electrica,
      {
        id: electrica.length,
        tablero: "",
        ckt: "",
        voltaje: 0,
        amperaje: 0,
        conexion: "",
      },
    ]);
    // setErrors([
    //   ...errors,
    //   { id: errors.length, name: "", tipo: "", obligatorio: "", graficar: "" },
    // ]);
  };

  const handleAddMecanica = async (event) => {
    event.preventDefault();
    setMecanica([
      ...mecanica,
      {
        id: mecanica.length,
        codigo: "",
        nombre: "",
        numeroValvula: "",
      },
    ]);
    // setErrors([
    //   ...errors,
    //   { id: errors.length, name: "", tipo: "", obligatorio: "", graficar: "" },
    // ]);
  };

  const handleAddOtros = async (event) => {
    event.preventDefault();
    setOtro([
      ...otro,
      {
        id: otro.length,
        codigo: "",
        nombre: "",
      },
    ]);
    // setErrors([
    //   ...errors,
    //   { id: errors.length, name: "", tipo: "", obligatorio: "", graficar: "" },
    // ]);
  };

  let inputElectrica;

  inputElectrica = electrica.map((inputs) => (
    <GridContainer key={inputs.id.toString()}>
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelCustomHorizontal}>
          Tablero:
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={4}>
        <CustomInput
          multiline
          labelText="Ej: P41D"
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleElectricaInput(e, inputs.id, 1),
            type: "text",
            value: electrica[inputs.id].tablero,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={1}>
        <FormLabel className={classes.labelCustomHorizontal}>CKT:</FormLabel>
      </GridItem>
      <GridItem xs={12} sm={5}>
        <CustomInput
          multiline
          labelText="Ej:10,12,14"
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleElectricaInput(e, inputs.id, 2),
            type: "text",
            value: electrica[inputs.id].ckt,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelCustomHorizontal}>
          Voltaje:
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={4}>
        <CustomInput
          multiline
          labelText="Ej: 120"
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleElectricaInput(e, inputs.id, 3),
            type: "number",
            value: electrica[inputs.id].voltaje,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={1}>
        <FormLabel className={classes.labelCustomHorizontal}>
          Amperaje:
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={5}>
        <CustomInput
          multiline
          labelText="Ej: 50"
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleElectricaInput(e, inputs.id, 4),
            type: "number",
            value: electrica[inputs.id].amperaje,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={1}>
        <FormLabel className={classes.labelCustomHorizontal}>
          Conexión:
        </FormLabel>
      </GridItem>
      <GridItem xs={null} sm={1}>
        <FormLabel className={classes.labelCustomHorizontal}></FormLabel>
      </GridItem>
      <GridItem xs={12} sm={4}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Conexión*
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            value={electrica[inputs.id].conexion}
            onChange={(e) => handleElectricaInput(e, inputs.id, 5)}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              Conexión
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="TWISLOCK"
            >
              Twislock
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="DUPLEX"
            >
              Duplex
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="DIRECTO"
            >
              Directo
            </MenuItem>
          </Select>
        </FormControl>
      </GridItem>
      <Divider />
    </GridContainer>
  ));

  let inputMecanica;
  inputMecanica = mecanica.map((inputs) => (
    <GridContainer key={inputs.id.toString()}>
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelCustomHorizontal}>Código:</FormLabel>
      </GridItem>
      <GridItem xs={12} sm={4}>
        <CustomInput
          multiline
          labelText="Ej: 1010"
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleMecanicaInput(e, inputs.id, 1),
            type: "text",
            value: mecanica[inputs.id].codigo,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={1}>
        <FormLabel className={classes.labelCustomHorizontal}>
          # Valvula:
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={5}>
        <CustomInput
          multiline
          labelText="1111"
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleMecanicaInput(e, inputs.id, 3),
            type: "text",
            value: mecanica[inputs.id].numeroValvula,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelCustomHorizontal}>Nombre:</FormLabel>
      </GridItem>
      <GridItem xs={12} sm={10}>
        <CustomInput
          multiline
          labelText="Ej: Aire"
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleMecanicaInput(e, inputs.id, 2),
            type: "text",
            value: mecanica[inputs.id].nombre,
          }}
        />
      </GridItem>
      <Divider />
    </GridContainer>
  ));

  let inputOtros;
  inputOtros = otro.map((inputs) => (
    <GridContainer key={inputs.id.toString()}>
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelCustomHorizontal}>Código:</FormLabel>
      </GridItem>
      <GridItem xs={12} sm={10}>
        <CustomInput
          multiline
          labelText="Ej: 2020"
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleOtroInput(e, inputs.id, 1),
            type: "text",
            value: otro[inputs.id].codigo,
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelCustomHorizontal}>Nombre:</FormLabel>
      </GridItem>
      <GridItem xs={12} sm={10}>
        <CustomInput
          multiline
          labelText="Ej: IT"
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleOtroInput(e, inputs.id, 2),
            type: "text",
            value: otro[inputs.id].nombre,
          }}
        />
      </GridItem>
      <Divider />
    </GridContainer>
  ));

  return (
    <GridContainer>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4 className={classes.cardTitle}>Crear nuevo polo</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <div className={classes.cardHeader}>
            <h4 className={classes.cardTitle}>Datos Generales</h4>
          </div>
          {/* Codigo Polo */}
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Código del polo:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomInput
                labelText="Ej: 1000"
                id="codPolo"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setCodigo(event.target.value);
                  },
                  type: "number",
                  value: codigo,
                }}
              />
              <Danger>
                {errors.codigo && (
                  <div className="invalid-feedback">{errors.codigo}</div>
                )}
              </Danger>
            </GridItem>
            <GridItem xs={12} xs={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Tipo*
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Tipo
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="SENCILLO"
                  >
                    SENCILLO
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="DOBLE"
                  >
                    DOBLE
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="TRIPLE"
                  >
                    TRIPLE
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="CUÁDRUPLE "
                  >
                    CUÁDRUPLE
                  </MenuItem>
                </Select>
                <Danger>
                  {errors.tipo && (
                    <div className="invalid-feedback">{errors.tipo}</div>
                  )}
                </Danger>
              </FormControl>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Area Productiva:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomInput
                labelText="Ej: Laser"
                id="area"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setArea(event.target.value);
                  },
                  type: "text",
                  value: area,
                }}
              />
              <Danger>
                {errors.area && (
                  <div className="invalid-feedback">{errors.area}</div>
                )}
              </Danger>
            </GridItem>
            <GridItem xs={12} xs={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Ubicación*
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={ubicacion}
                  onChange={(e) => setUbicacion(e.target.value)}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Ubicación
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="1"
                  >
                    CER 1
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="2"
                  >
                    CER 2
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="3"
                  >
                    CER 3
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="4"
                  >
                    CER 4
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="5"
                  >
                    CER 5
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="6"
                  >
                    CER 6
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="7"
                  >
                    CER 7
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="8"
                  >
                    CER 8
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="9"
                  >
                    CER 9
                  </MenuItem>
                </Select>
                <Danger>
                  {errors.ubicacion && (
                    <div className="invalid-feedback">{errors.ubicacion}</div>
                  )}
                </Danger>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Código de polo anterior:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomInput
                labelText="Ej: 1000"
                id="codPoloAnt"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setCodigoAnt(event.target.value);
                  },
                  type: "text",
                  value: codigoAnt,
                }}
              />
            </GridItem>
          </GridContainer>

          <Divider />
          <br />
          <div className={classes.cardHeader}>
            <h4 className={classes.cardTitle}>Facilidades eléctricas</h4>
          </div>
          <form>{inputElectrica}</form>
          <Divider />
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Button
                fullWidth
                simple
                color="success"
                onClick={handleAddElectrica}
              >
                {/* <Button fullWidth color="success"> */}
                <AddCircle className={classes.icons} /> Añadir Electrica
              </Button>
            </GridItem>
          </GridContainer>
          <Divider />
          <br />
          <div className={classes.cardHeader}>
            <h4 className={classes.cardTitle}>Facilidades mecanicas</h4>
          </div>
          <form>{inputMecanica}</form>
          <Divider />
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Button
                fullWidth
                simple
                color="success"
                onClick={handleAddMecanica}
              >
                {/* <Button fullWidth color="success"> */}
                <AddCircle className={classes.icons} /> Añadir Mecanica
              </Button>
            </GridItem>
          </GridContainer>
          <Divider />
          <br />
          <div className={classes.cardHeader}>
            <h4 className={classes.cardTitle}>Otras Facilidades</h4>
          </div>
          <form>{inputOtros}</form>
          <Divider />
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Button fullWidth simple color="success" onClick={handleAddOtros}>
                {/* <Button fullWidth color="success"> */}
                <AddCircle className={classes.icons} /> Añadir Otro
              </Button>
            </GridItem>
          </GridContainer>
          <Divider />
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Button fullWidth color="success" onClick={verifySubmit}>
                {/* <Button fullWidth color="success"> */}
                Guardar Polo
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </GridContainer>
  );
}
